.neonText {
  animation: flicker 1.5s infinite alternate;
  color: #fff;
}

.neonTextSuccess {
  animation: flickerSuccess 1s ease-in-out infinite alternate;
  color: #fff;
}

@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 40px #0fa,
      0 0 80px #0fa, 0 0 90px #0fa, 0 0 100px #0fa, 0 0 150px #0fa;
  }

  20%,
  24%,
  55% {
    text-shadow: none;
  }
}

@keyframes flickerSuccess {
  0% {
    color: #1de50d;
    text-shadow: 0 0 0.25px #1de50d, 0 0 0.5px #1de50d, 0 0 0.75px #1de50d,
      0 0 1px #1de50d, 0 0 1.25px #1de50d, 0 0 1.5px #1de50d, 0 0 1.75px #1de50d,
      0 0 2px #1de50d;
  }
  ,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    color: #00e70166;
    text-shadow: 0 0 0.5px #00e70166, 0 0 1.5px #00e70166, 0 0 2px #00e70166,
      0 0 2.5px #00e70166, 0 0 3px #00e70166, 0 0 3.5px #00e70166, 0 0 4px #00e70166,
      0 0 4.5px #00e70166;
  }
}
